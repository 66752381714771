import React from "react";

export default function GallaryCase({leftIMG,rightIMG,bottomIMG}) {
  return (
    <>
    <div className="caseFirstImgBox">
        <div className="GalleryBox">
          <img className="galleryImg" src={leftIMG} alt="CaseIMG" />
        </div>
        <div className="GalleryBox">
          <img className="galleryImg" src={rightIMG} alt="CaseIMG" />
        </div>
      </div>
      <img className="GalleryCaseImg" src={bottomIMG} alt="CaseIMG" />
    </>
  );
}
