import React, { useState, useEffect } from "react";

export default function Timer({ activateTimer }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let interval;
    if (activateTimer) {
      interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount >= 100) {
            clearInterval(interval);
            return prevCount;
          }
          return prevCount + 1;
        });
      }, 12);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [activateTimer]);

  return <h2 className="timer">{count}</h2>;
}
