import React, { useRef, useState, useContext } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { CustomEase } from "gsap/CustomEase";
import { PreloaderContext } from "../App";
import ScrollUpAnimateLogo from "./ScrollUpAnimateLogo";
import Header from "./Header";

export default function MainBlock({
  headerFirstWord,
  headerSecondWord,
  headerThirdWord,
}) {
  const elementRref = useRef();
  const lineBoxRef = useRef(null);
  const ofContainerRef = useRef(null);
  const blackLineRef = useRef(null);
  const homeGradientBlockRef = useRef(null);
  const wordBoxRef = useRef(null);
  const MaximumWordHomePageBox = useRef(null);
  const MaximumBlockHeaderBottomBoxHomePage = useRef(null);

  const { preloaderAnimateFinished, setPreloaderAnimateFinished } =
    useContext(PreloaderContext);
  const [activateLogoScroll, setActivateLogoScroll] = useState(false);

  const createScrollTriggerConfig = (triggerElement, start, end) => ({
    trigger: triggerElement,
    start: start,
    end: end,
    scrub: true,
  });

  useGSAP(
    () => {
      if (preloaderAnimateFinished) {
        gsap
          .timeline()
          .fromTo(
            wordBoxRef.current,
            { opacity: 0, y: 120 },
            { opacity: 1, y: 0, duration: 0.5 }
          )
          .fromTo(
            lineBoxRef.current,
            { opacity: 0, y: 110 },
            { opacity: 1, y: 0, duration: 0.5 },
            "-=0.3"
          )
          .fromTo(
            ofContainerRef.current,
            { opacity: 0, y: 120 },
            {
              opacity: 1,
              y: 0,
              duration: 0.5,
              onComplete: () => {
                setActivateLogoScroll(true);
              },
            },
            "-=0.3"
          )
          .add(() => {
            if (window.location.pathname === "/") {
              gsap.fromTo(
                homeGradientBlockRef.current,
                { opacity: 0 },
                { opacity: 1 }
              );
            }
          })
          .fromTo(
            blackLineRef.current,
            { flex: "0 1 auto" },
            {
              flex: "1 1 auto",
              duration: 0.5,
              ease: CustomEase.create("custom", "M0,0 C0.674,0 0.344,1 1,1 "),
            }
          );
      }

      if (window.location.pathname === "/") {
        gsap
          .timeline()
          .fromTo(
            MaximumWordHomePageBox.current,
            { y: -300 },
            {
              y: 0,
              scrollTrigger: createScrollTriggerConfig(
                elementRref.current,
                "top+=200 bottom",
                "bottom bottom"
              ),
            }
          )
          .fromTo(
            MaximumBlockHeaderBottomBoxHomePage.current,
            { y: -200 },
            {
              y: 0,
              scrollTrigger: createScrollTriggerConfig(
                elementRref.current,
                "top+=200 bottom",
                "bottom bottom"
              ),
            }
          )
          .fromTo(
            blackLineRef.current,
            { transformOrigin: "right", scaleX: 0 },
            {
              scaleX: 1,
              scrollTrigger: createScrollTriggerConfig(
                elementRref.current,
                "bottom-=100 bottom",
                "bottom bottom"
              ),
            }
          );
      }
      return () => {
        setPreloaderAnimateFinished(false);
      };
    },
    { scope: elementRref, dependencies: [preloaderAnimateFinished] }
  );

  return (
    <div ref={elementRref} className="homePage">
      {window.location.pathname === "/" && (
        <div ref={homeGradientBlockRef} className="homeGradientBlock"></div>
      )}
      <div className="MaximumBlockHeader MaximumOfHeaderStyle MaximumBlockHeaderMain">
        <div ref={MaximumWordHomePageBox} className="MaximumWordHomePageBox">
          <p ref={wordBoxRef} className="MainBlockHeaderFirstWord">
            {headerFirstWord}
          </p>
        </div>
        <div
          ref={MaximumBlockHeaderBottomBoxHomePage}
          className="MaximumBlockHeaderBottomBox"
        >
          <div ref={lineBoxRef} className="MaximumBlockHeaderLineBox">
            <p className="wordOf MaximumOfHeaderStyleItalic">
              {headerSecondWord}
            </p>
            <div ref={blackLineRef} className="MaximumBlackLine"></div>
          </div>
          <div
            ref={ofContainerRef}
            className="MaximumOFContainer MainBlockHeaderThirdWord"
          >
            <p>{headerThirdWord.toUpperCase()}</p>
          </div>
        </div>
      </div>
      {activateLogoScroll ? (
        <ScrollUpAnimateLogo activeAnimate={activateLogoScroll} />
      ) : (
        ""
      )}
      {activateLogoScroll ? <Header activeAnimate={activateLogoScroll} /> : ""}
    </div>
  );
}
