import React from "react";
import useTextAnimation from "../../../components/SplitTextAnimateFunc";

export default function AboutTextBlock() {
  const aboutRef = useTextAnimation();
  return (
    <>
      <div className="aboutTextBlock">
        <div className="aboutWord italicStyle"></div>
        <div className="aboutText aboutTextBlockMain">
          <div ref={aboutRef} className="aboutTextBoxes aboutTextBoxesAboutPage">
            <p className="DescriptionBigTextStyle AboutDescription">
              At Maxima Group, we are a team of experienced digital designers
              and developers dedicated to helping our clients achieve their
              goals through beautiful and effective digital design.
            </p>
            <p className="DescriptionNormalTextStyle AboutPageTextBlockDescription">
              We believe in the power of good design to communicate, engage, and
              inspire. Our team is constantly learning and experimenting with
              new technologies and design trends to ensure that our clients are
              always ahead of the curve.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
