import React from "react";
import { Link } from "react-router-dom";

export default function FooterMobile() {
  return (
    <>
      <div className="footerBlockMobile">
        <div className="footerContactsMobile">
          <div className="footerContactsBoxes">
            <p className="footerContactId DescriptionNormalTextStyle">Email</p>
            <a
              className="footerContactInfo DescriptionLittleTextStyle"
              href="mailto:info@maximagroup.ae"
            >
              info@maximagroup.ae
            </a>
          </div>
          <div className="footerContactsBoxes">
            <p className="footerContactId DescriptionNormalTextStyle">Phone</p>
            <a
              className="footerContactInfo DescriptionLittleTextStyle"
              href="tel:+971 4 282 3360"
            >
              +971 4 282 3360
            </a>
          </div>
          <div className="footerContactsBoxes">
            <p className="footerContactId DescriptionNormalTextStyle">
              Address
            </p>
            <a
              className="footerContactInfo DescriptionLittleTextStyle"
              href="https://www.google.com/maps?q=Office 404, City 4 Building, Al Garhoud, 53 St., Dubai. United Arab Emirates"
              target="_blank"
              rel="noopener noreferrer"
            >
              Office 404, City 4 Building, Al Garhoud, 53 St., Dubai. United
              Arab Emirates
            </a>
          </div>
          <div className="footerContactsBoxes">
            <p className="footerContactId DescriptionNormalTextStyle">
              Social Media
            </p>
            <div className="footeMobileSocial DescriptionNormalTextStyle">
              <a
                href="https://www.facebook.com/MaximaGroupUAE"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <p className="footeMobileSocialItem">Facebook</p>
              </a>
              <a
                href="https://www.instagram.com/maximagroupuae/"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <p className="footeMobileSocialItem">instagram</p>
              </a>
              <a
                href="https://www.linkedin.com/company/maxima-group-uae"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <p className="footeMobileSocialItem">Linkedin</p>
              </a>
              <a
                href="https://twitter.com/MaximaGroupUAE"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <p className="footeMobileSocialItem">Twitter</p>
              </a>
            </div>
          </div>
        </div>

        <div className="Contacts">
          <p>CONTACTS</p>
        </div>
        <div className="footerDescriptionMobile DescriptionLittleTextStyle">
          <p>
            Maxima Group is a leading Web Design and Web Development agency
            established in Dubai with a renown expertise in design, software
            development and marketing.
          </p>
        </div>
        <div className="logoFooterBoxMobile">
          <Link to="/">
            <img
              className="logoFooterMobile"
              src="/img/MaximagroupWhiteLogo.svg"
              alt="logoFooter"
            />
          </Link>
        </div>
        <div className="footerMenu NavigationMenuBigText">
          <Link to="/about">
            <h3 className="textHover"> ABOUT US </h3>
          </Link>
          <Link to="/portfolio">
            <h3 className="textHover"> PORTFOLIO</h3>
          </Link>
          <h3 className="textHover">
            <a href="mailto:info@maximagroup.ae">GET IN TOUCH ↳</a>
          </h3>
        </div>
      </div>
    </>
  );
}
