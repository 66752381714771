import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AnimateTitleBlock from "../../../components/AnimateTitleBlock";

export default function Success() {
  const [activeCard, setActiveCard] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const successRef = useRef();
  const arrowRefs = useRef([]);
  const contentRefs = useRef([]);

  const cards = [
    {
      id: 1,
      title: "HOLY QURAN ACADEMY",
      description: [
        {
          text: '"The best digital agency I worked with. Maxima team is unmatched with their design quality and professional standards."',
        },
        { text: "\n Jamal Al Suwaidi.", style: { fontWeight: "bold" } },
        {
          text: "\n Head of Digital Trandformation.",
          style: { fontWeight: "bold" },
        },
      ],
      img: "./img/success/firstSuccess.svg",
      shaperotation: "135",
      shapeHeight: "110%",
      shapeMobileHeight: "75%",
    },
    {
      id: 2,
      title: "BEE'AH",
      description: [
        {
          text: '"The Maxima team is undoubtedly professional. I\'ve had the privilege of working with them on several website and mobile application projects, as well as media coverage initiatives."',
        },
        { text: "\n Imad Juhair.", style: { fontWeight: "bold" } },
        { text: "\n Marketing Director.", style: { fontWeight: "bold" } },
      ],
      img: "./img/success/beahSuccess.svg",
      shaperotation: "0",
      shapeHeight: "40%",
      shapeMobileHeight: "50%",
    },
    {
      id: 3,
      title: "BEDU",
      description: [
        {
          text: '"I\'ve had the pleasure of collaborating with Maxima on numerous projects. Their team exemplifies professionalism, adeptness in cutting-edge technologies, and a commitment to credibility and excellence."',
        },
        { text: "\n Amin Al Zarouni.", style: { fontWeight: "bold" } },
        { text: "\n CEO.", style: { fontWeight: "bold" } },
      ],
      // description:
      //   '',
      img: "./img/success/beduSuccess.svg",
      shaperotation: "45",
      shapeHeight: "110%",
      shapeMobileHeight: "75%",
    },
    {
      id: 4,
      title: "SAHAB SMART SOLUTIONS",
      description: [
        {
          text: '"If you seek excellence in user interface design, look no further than the Maxima team. They possess valuable insights and extensive experience in this field."',
        },
        { text: "\n Abdul Rahim Al Zarouni.", style: { fontWeight: "bold" } },
        {
          text: "\n Business Development Manager.",
          style: { fontWeight: "bold" },
        },
      ],

      img: "./img/success/success4.svg",
      shaperotation: "0",
      shapeHeight: "40%",
      shapeMobileHeight: "50%",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    gsap.set(arrowRefs.current[0], { rotation: 180 });
  }, []);

  const handleCardClick = (index) => {
    if (index !== activeCard) {
      gsap.to(contentRefs.current[activeCard], { height: 0, duration: 0.5 });
      gsap.to(arrowRefs.current[activeCard], { rotation: 0, duration: 0.5 });

      gsap.to(contentRefs.current[index], { height: "auto", duration: 0.5 });
      gsap.to(arrowRefs.current[index], { rotation: 180, duration: 0.5 });

      gsap.to(".shapeSucess", {
        rotation: cards[index].shaperotation,
        duration: 1,
      });
      gsap.to(".shapeSucess", {
        height: cards[index].shapeHeight,
        duration: 1,
      });
      setActiveCard(index);
    }
    if (isMobile) {
      gsap.to(".shapeSucessMobile", {
        rotation: cards[index].shaperotation,
        duration: 1,
      });
      gsap.to(".shapeSucessMobile", {
        height: cards[index].shapeMobileHeight,
        duration: 1,
      });
    }
  };
  useEffect(() => {
    let animationSuccessHeader = gsap
      .timeline({ paused: true })
      .fromTo(
        ".rightSuccessBox",
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 1 }
      )
      .fromTo(
        ".shapeSucess",
        { clipPath: "inset(0% 0% 100% 0%)" },
        {
          clipPath: "inset(0% 0% 0% 0%)",
          duration: 1,
        },
        "<"
      )
      .fromTo(
        ".successBoxImg",
        { y: 150, opacity: 0 },
        {
          y: 0,
          duration: 1,
          opacity: 1,
        },
        "<"
      );
      let stSuccess;
      let timeoutIdSuccess = setTimeout(() => {
        stSuccess = ScrollTrigger.create({
          trigger: ".successBlock",
          start: "bottom-=25% top",
          end: "top top",
          onEnterBack: () => {
            animationSuccessHeader.play();
          },
        });
      }, 100); 
    
      return () => {
        clearTimeout(timeoutIdSuccess);
        if (stSuccess) stSuccess.kill();
      };
  }, []);
  return (
    <>
      <div ref={successRef} className="successBlock">
        <div className="successMainContainer">
          <div className="leftSuccessBox">
            <div className="shapeSucess"></div>
            <div className="successBoxImg">
              <img
                className="successImg"
                src={cards[activeCard].img}
                alt="successImg"
              />
            </div>
          </div>
          <div className="rightSuccessBox">
            {cards.map((card, index) => (
              <div
                className="successTabs"
                key={card.id}
                onClick={() => handleCardClick(index)}
              >
                <div className="sucessHeaderBlock">
                  <p
                    className="sucessTabHeader BlockHeader"
                    style={{ color: activeCard === index ? "white" : "black" }}
                  >
                    {card.title}
                  </p>
                  <div
                    className="arrovShape"
                    ref={(el) => (arrowRefs.current[index] = el)}
                    style={{
                      borderTopColor: activeCard === index ? "white" : "black",
                    }}
                  ></div>
                </div>
                <div
                  className="sucessTabDescription DescriptionNormalTextStyle"
                  ref={(el) => (contentRefs.current[index] = el)}
                  style={{
                    overflow: "hidden",
                    height: index !== 0 ? "0" : undefined,
                  }}
                >
                  {card.description.map((item, index) => (
                    <span key={index} style={item.style}>
                      {item.text}
                    </span>
                  ))}
                  {isMobile && (
                    <div className="successBoxImgMobile">
                      <div className="shapeSucessMobile"></div>
                      <img
                        className="successImgMobile"
                        src={cards[activeCard].img}
                        alt="successImg"
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <AnimateTitleBlock
          headerFirstWord="MAXIMUM"
          headerSecondWord="OF"
          headerThirdWord="SUCCESS"
        />
      </div>
      <div className="successGradientBlock"></div>
    </>
  );
}
