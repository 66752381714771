import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

import SplitType from "split-type";
import AnimateTitleBlock from "../../../components/AnimateTitleBlock";

gsap.registerPlugin(CustomEase);

export default function Works() {
  let worksRef = useRef();

  useEffect(() => {
    let splitRow = new SplitType(
      ".worksBoxesTextBlokBoxes1, .worksBoxesTextBlokBoxes2",
      {
        types: ["words"],
      }
    );
    let splitRow_2 = new SplitType(
      ".worksBoxesTextBlokBoxes3, .worksBoxesTextBlokBoxes4",
      {
        types: ["words"],
      }
    );
    let splitRowSecond = new SplitType(
      ".worksBoxesTextBlokBoxes5, .worksBoxesTextBlokBoxes6",
      {
        types: ["words"],
      }
    );
    let splitRowBigImg = new SplitType(".worksBigBlockText", {
      types: ["words"],
    });
    // let splitSeeAll = new SplitType(".worksWordSeeAll", {
    //   types: ["words"],
    // });
    // .from(
    //   splitSeeAll.words,
    //   {
    //     opacity: 0,
    //     ease: "ease.in",
    //     rotationZ: "0",
    //     y: 40,
    //     stagger: 0.3,
    //     duration: 1,
    //   },
    //   "<"
    // )
    let animation = gsap
      .timeline({ paused: true })
      .fromTo(
        ".worksBoxes1",
        { y: 100 },
        {
          y: 0,
          duration: 1,
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<"
      )
      .fromTo(
        ".worksImg1",
        { clipPath: "inset(100% 0% 0% 0%)" },
        {
          duration: 0.8,
          clipPath: "inset(0% 0% 0% 0%)",
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<"
      )
      .from(
        splitRow.words,
        {
          opacity: 0,
          ease: "ease.in",
          rotationZ: "0",
          y: 20,
          stagger: 0.04,
          duration: 1,
        },
        "<"
      )
      .fromTo(
        ".worksBoxes2",
        { y: 150 },
        {
          y: 0,
          duration: 0.5,
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<0.2"
      )
      .fromTo(
        ".worksImg2",
        { clipPath: "inset(100% 0% 0% 0%)" },
        {
          clipPath: "inset(0% 0% 0% 0%)",
          duration: 0.5,
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<0.2"
      );

    let animation_2 = gsap
      .timeline({ paused: true })
      .fromTo(
        ".worksBoxes3",
        { y: 150, clipPath: "inset(100% 0% 0% 0%)" },
        {
          y: 0,
          duration: 0.7,
          clipPath: "inset(0% 0% 0% 0%)",
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        }
      )
      .fromTo(
        ".worksBoxes4",
        { y: 100, clipPath: "inset(100% 0% 0% 0%)" },
        {
          y: 0,
          duration: 0.8,
          clipPath: "inset(0% 0% 0% 0%)",
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<0.25"
      )
      .from(
        splitRow_2.words,
        {
          opacity: 0,
          ease: "ease.in",
          rotationZ: "0",
          y: 20,
          stagger: 0.04,
          duration: 0.8,
        },
        "<"
      );

    let animationSecond = gsap
      .timeline({ paused: true })
      .fromTo(
        ".worksBoxes5",
        { clipPath: "inset(100% 0% 0% 0%)", y: 150 },
        {
          y: 0,
          clipPath: "inset(0% 0% 0% 0%)",
          duration: 1,
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        }
      )
      .fromTo(
        ".worksBoxes6",
        { clipPath: "inset(100% 0% 0% 0%)", y: 150 },
        {
          y: 0,
          clipPath: "inset(0% 0% 0% 0%)",
          duration: 1,
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<0.2"
      )
      .from(
        splitRowSecond.words,
        {
          opacity: 0,
          ease: "ease.in",
          rotationZ: "0",
          y: 20,
          stagger: 0.04,
          duration: 0.8,
        },
        "<"
      );

    let animationThird = gsap
      .timeline({ paused: true })
      .fromTo(
        ".worksBigBackgroundContainer",
        { y: 150 },
        {
          y: 0,
          duration: 1,
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        }
      )
      .fromTo(
        ".worksBoxesBigImg",
        { clipPath: "inset(100% 0% 0% 0%)" },
        {
          duration: 0.8,
          clipPath: "inset(0% 0% 0% 0%)",
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<"
      )
      // .fromTo(
      //   ".worksBoxesBigImg",
      //   { clipPath: "inset(100% 0% 0% 0%)", y: 150 },
      //   {
      //     y: 0,
      //     clipPath: "inset(0% 0% 0% 0%)",
      //     duration: 1,
      //     ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
      //   }
      // )
      .from(
        splitRowBigImg.words,
        {
          opacity: 0,
          ease: "ease.in",
          rotationZ: "0",
          y: 20,
          stagger: 0.04,
          duration: 0.8,
        },
        "<"
      );

    const worksBigBackgroundContainer = document.querySelector(
      ".worksBigBackgroundContainer"
    );
    worksBigBackgroundContainer.addEventListener("mouseenter", function () {
      gsap.to(".worksBigImageLoaderLeftItem, .worksBigImageLoaderRightItem", {
        clipPath: "inset(0% 0% 0% 0%)",
        duration: 0.2,
      });
      gsap.to(".worksBigImgHover", {
        duration: 0.3,
        clipPath: "inset(0% 0% 0% 0%)",
      });
      gsap.to(".BlockHeaderWorks, .BlockDescriptionWorks", {
        color: "blue",
        // textDecoration: "underline",
      });
    });
    worksBigBackgroundContainer.addEventListener("mouseleave", function () {
      gsap.to(".worksBigImageLoaderLeftItem, .worksBigImageLoaderRightItem", {
        clipPath: "inset(100% 0% 0% 0%)",
        duration: 0.2,
      });
      gsap.to(".worksBigImgHover", {
        duration: 0.3,
        clipPath: "inset(0% 0% 100% 0%)",
      });
      gsap.to(".BlockHeaderWorks, .BlockDescriptionWorks", {
        color: "black",
        // textDecoration: "none",
        borderBottom: "0px",
      });
    });

    const elements = document.querySelectorAll(".worksBoxes");
    elements.forEach((element) => {
      element.addEventListener("mouseenter", function (event) {
        const hoverImage = event.currentTarget.querySelector(".worksImgHover");
        const textHeader = event.currentTarget.querySelector(
          ".worksTextDescription"
        );
        const DescriptionBigTextStyleWorks = event.currentTarget.querySelector(
          ".DescriptionBigTextStyleWorks"
        );
        const textDescription = event.currentTarget.querySelector(
          ".DescriptionLittleTextStyle"
        );
        gsap.to(hoverImage, { duration: 0.3, clipPath: "inset(0% 0% 0% 0%)" });
        gsap.to([textHeader, textDescription, DescriptionBigTextStyleWorks], {
          color: "blue",
        });
        if (
          event.currentTarget.querySelector(".worksLoaderRightItem") ||
          event.currentTarget.querySelector(".worksLoaderLeftItem")
        ) {
          gsap.to(".worksLoaderLeftItem, .worksLoaderRightItem", {
            clipPath: "inset(0% 0% 0% 0%)",
            duration: 0.2,
          });
        }
      });

      element.addEventListener("mouseleave", function (event) {
        const hoverImage = event.currentTarget.querySelector(".worksImgHover");
        const textHeader = event.currentTarget.querySelector(
          ".worksTextDescription"
        );
        const DescriptionBigTextStyleWorks = event.currentTarget.querySelector(
          ".DescriptionBigTextStyleWorks"
        );
        const textDescription = event.currentTarget.querySelector(
          ".DescriptionLittleTextStyle"
        );
        gsap.to(hoverImage, {
          duration: 0.3,
          clipPath: "inset(0% 0% 100% 0%)",
        });
        gsap.to([textHeader, textDescription, DescriptionBigTextStyleWorks], {
          color: "black",
        });
        if (
          event.currentTarget.querySelector(".worksLoaderRightItem") ||
          event.currentTarget.querySelector(".worksLoaderLeftItem")
        ) {
          gsap.to(".worksLoaderRightItem, .worksLoaderLeftItem", {
            clipPath: "inset(100% 0% 0% 0%)",
            duration: 0.3,
          });
        }
      });
    });

    let st1, st1_2, st2, st3;
    let timeoutId1 = setTimeout(() => {
      st1 = ScrollTrigger.create({
        trigger: ".firstWorksMainContainers",
        start: "center top",
        onEnterBack: () => animation.play(),
      });
    }, 100);

    let timeoutId1_2 = setTimeout(() => {
      st1_2 = ScrollTrigger.create({
        trigger: ".secondWorksMainContainers",
        start: "center top",
        onEnterBack: () => animation_2.play(),
      });
    }, 100);

    let timeoutId2 = setTimeout(() => {
      st2 = ScrollTrigger.create({
        trigger: ".worksBigContainers",
        start: "center top",
        end: "top top",
        onEnterBack: () => animationSecond.play(),
      });
    }, 100);

    let timeoutId3 = setTimeout(() => {
      st3 = ScrollTrigger.create({
        trigger: ".worksBigBackgroundBlock",
        start: "center top",
        end: "top top",
        onEnterBack: () => animationThird.play(),
      });
    }, 100);

    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId1_2);
      clearTimeout(timeoutId2);
      clearTimeout(timeoutId3);
      if (st1) st1.kill();
      if (st1_2) st1_2.kill();
      if (st2) st2.kill();
      if (st3) st3.kill();
    };
  }, []);

  return (
    <>
      <div ref={worksRef} className="worksBlock">
        <div className="worksBigBackgroundBlock">
          <div className="worksBigBackgroundContainer">
            <div className="worksBigBlockText">
              <h4 className="BlockHeader BlockHeaderWorks">THE 2117</h4>
              <p className="DescriptionWorks BlockDescriptionWorks DescriptionBigTextStyle worksTextDescription DescriptionBigTextStyleWorks">
                To enable the prosperity of humanity through exploration
              </p>
            </div>
            <div className="imgWorksBox worksBoxesBigImg">
              <div className="worksBigImageHoverLoader">
                <div className="worksBigImageLoaderItem worksBigImageLoaderLeftItem"></div>
                <div className="worksBigImageLoaderItem worksBigImageLoaderRightItem"></div>
              </div>
              <img
                className="worksBigImg"
                src="./img/works/works7Big.svg"
                alt="worksImg"
              />
              <div className="worksBigImageHoverBox">
                <img
                  className="worksBigImgHover"
                  src="./img/works/bigImgWorksHover.svg"
                  alt="worksImg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="worksMainContainers thirdWorksMainContainers">
          <div className="worksBigContainers">
            <div className="worksBoxes worksBoxes5">
              <div className="worksBoxesTextBlok worksBoxesTextBlokBoxes5">
                <p className="DescriptionBigTextStyle DescriptionBigTextStyleWorks">
                  Sabaa' Sanabel
                </p>
                <p className="DescriptionWorks DescriptionNormalTextStyle worksTextDescription">
                  Building the Brand of Sharjah' biggest wheat farms
                </p>
              </div>
              <div className="imgWorksBox worksBoxes5">
                <div className="worksHoverLoader">
                  <div className="worksLoaderItem worksLoaderLeftItem"></div>
                  <div className="worksLoaderItem worksLoaderRightItem"></div>
                </div>
                <img
                  className="worksImg worksImgFirst worksImg5"
                  src="./img/works/works5.jpg"
                  alt="worksImg"
                />
                <img
                  className="worksImg worksImgFirst worksImgHover"
                  src="./img/works/works5hover.jpg"
                  alt="worksImg"
                />
              </div>
            </div>
            <div className="worksBoxes worksBoxes6">
              <div className="worksBoxesTextBlok worksBoxesTextBlokBoxes6">
                <p className="DescriptionBigTextStyle DescriptionBigTextStyleWorks">
                  Holy Quran Academy
                </p>
                <p className="DescriptionWorks DescriptionNormalTextStyle worksTextDescription">
                  A state-of-art platform, app, and corproate brand
                </p>
              </div>
              <div className="imgWorksBox">
                <img
                  className="worksImg worksImgSecond worksImg6"
                  src="./img/works/works6.jpg"
                  alt="worksImg"
                />
                <img
                  className="worksImg worksImgHover worksSecondImgHover"
                  src="./img/works/works6hover.jpg"
                  alt="worksImg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="worksMainContainers secondWorksMainContainers">
          <div className="worksContainers">
            <div className="worksBoxes worksBoxes3">
              <div className="worksBoxesTextBlok worksBoxesTextBlokBoxes4">
                <p className="DescriptionNormalTextStyle worksTextDescription">
                  SEWA Brand
                </p>
                <p className="DescriptionWorks DescriptionLittleTextStyle">
                  Uplifting the brand with delicate touches
                </p>
              </div>
              <div id="portfolio" className="imgWorksBox imgWorksBoxesSmall">
                <img
                  className="worksImg worksImgFirst worksImg3"
                  src="./img/works/works3.jpg"
                  alt="worksImg"
                />
                <img
                  className="worksImg worksImgFirst worksImgHover"
                  src="./img/works/works3hover.jpg"
                  alt="worksImg"
                />
              </div>
            </div>
            <div className="worksBoxes worksBoxes4">
              <div className="worksBoxesTextBlok worksBoxesTextBlokBoxes4">
                <p className="DescriptionNormalTextStyle worksTextDescription">
                  BEEAH Group
                </p>
                <p className="DescriptionWorks DescriptionLittleTextStyle">
                  Tandeef website and mobile apps
                </p>
              </div>
              <div className="imgWorksBox imgWorksBoxesSmall">
                <img
                  className="worksImg worksImgSecond worksImg4"
                  src="./img/works/works4.png"
                  alt="worksImg"
                />
                <img
                  className="worksImg worksImgHover worksSecondImgHover"
                  src="./img/works/works4hover.jpg"
                  alt="worksImg"
                />
              </div>
            </div>
          </div>
        </div>

        <Link to="/case">
          <div className="worksMainContainers firstWorksMainContainers">
            <div className="worksContainers">
              <div className="worksBoxes worksBoxes1">
                <div className="worksBoxesTextBlok worksBoxesTextBlokBoxes1">
                  <p className="DescriptionNormalTextStyle worksTextDescription">
                    Digital Sharjah
                  </p>
                  <p className="DescriptionWorks DescriptionLittleTextStyle">
                    The mega platform for Sharjah Government
                  </p>
                </div>
                <div className="imgWorksBox imgWorksBoxesSmall">
                  <img
                    className="worksImg worksImgFirst worksImg1"
                    src="./img/works/works1.jpg"
                    alt="worksImg"
                  />
                  <img
                    className="worksImg worksImgFirst worksImgHover"
                    src="./img/works/works1hover.jpg"
                    alt="worksImg"
                  />
                </div>
              </div>

              <div id="portfolioMobile" className="worksBoxes worksBoxes2">
                <div className="worksBoxesTextBlok worksBoxesTextBlokBoxes2">
                  <p className="DescriptionNormalTextStyle worksTextDescription">
                    Sharrai Classified Ads
                  </p>
                  <p className="DescriptionWorks DescriptionLittleTextStyle">
                    Website & mobile apps
                  </p>
                </div>
                <div className="imgWorksBox imgWorksBoxesSmall">
                  <img
                    className="worksImg worksImgSecond worksImg2"
                    src="./img/works/works2.jpg"
                    alt="worksImg"
                  />
                  <img
                    className="worksImg worksImgHover worksSecondImgHover"
                    src="./img/works/works2hover.jpg"
                    alt="worksImg"
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <AnimateTitleBlock
          headerFirstWord="MAXIMUM"
          headerSecondWord="OF"
          headerThirdWord="work"
        />
      </div>
    </>
  );
}
