import React from "react";
import FooterMobile from "./FooterMobile";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <FooterMobile />
      <div className="footerBlock">
        <div className="footer2020 DescriptionLittleTextStyle">
          <p> © 2023, Maxima Group. All Rights Reserved.</p>
        </div>
        <div className="footerConteiner">
          <div className="leftFooterBox NavigationMenuBigText">
            <Link to="/about">
              <h3 className="textHover"> ABOUT US </h3>
            </Link>
            <Link to="/portfolio">
              <h3 className="textHover"> PORTFOLIO</h3>
            </Link>
            <h3 className="textHover">
              <a href="mailto:info@maximagroup.ae">GET IN TOUCH ↳</a>
            </h3>
          </div>
          <div className="rightFooterBox">
            <div className="rightUpperBoxFooter">
              <div className="logoFooterBox">
                <Link to="/">
                  <img
                    className="logoFooter"
                    src="/img/MaximaGrpup_Logo.svg"
                    alt="logoFooter"
                  />
                </Link>
              </div>
              <div className="footerDescription DescriptionLittleTextStyle">
                <p>
                  Maxima Group is a leading Web Design and Web Development
                  agency established in Dubai with a renown expertise in design,
                  software development and marketing.
                </p>
              </div>
            </div>
            <div className="rightBottomBoxFooter">
              <div className="footerContacts">
                <div className="footerContactsBoxes">
                  <p className="footerContactId DescriptionNormalTextStyle">
                    Email
                  </p>
                  <a
                    className="footerContactInfo DescriptionLittleTextStyle"
                    href="mailto:info@maximagroup.ae"
                  >
                    info@maximagroup.ae
                  </a>
                </div>
                <div className="footerContactsBoxes">
                  <p className="footerContactId DescriptionNormalTextStyle">
                    Phone
                  </p>
                  <a
                    className="footerContactInfo DescriptionLittleTextStyle"
                    href="tel:+971 4 282 3360"
                  >
                    +971 4 282 3360
                  </a>
                </div>
                <div className="footerContactsBoxes">
                  <p className="footerContactId DescriptionNormalTextStyle">
                    Address
                  </p>
                  <a
                    className="footerContactInfo DescriptionLittleTextStyle"
                    href="https://www.google.com/maps?q=Office 404, City 4 Building, Al Garhoud, 53 St., Dubai. United Arab Emirates"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Office 404, City 4 Building, Al Garhoud, 53 St., Dubai.
                    United Arab Emirates
                  </a>
                </div>
              </div>
              <div className="footerSocialNetworks DescriptionNormalTextStyle">
                <a
                  href="https://www.facebook.com/MaximaGroupUAE"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <h4 className="footerContactHeader">Facebook</h4>
                </a>
                <a
                  href="https://www.instagram.com/maximagroupuae/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <h4 className="footerContactHeader">instagram</h4>
                </a>
                <a
                  href="https://www.linkedin.com/company/maxima-group-uae"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <h4 className="footerContactHeader">Linkedin</h4>
                </a>
                <a
                  href="https://twitter.com/MaximaGroupUAE"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <h4 className="footerContactHeader">Twitter</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
