const checkMediaLoaded = (callback) => {
  const images = document.querySelectorAll('img');
  const totalImages = images.length;
  let loadedImages = 0;
  console.log(totalImages)

  const imageLoaded = () => {
    loadedImages++;
    if (loadedImages === totalImages) {
      callback(); // Вызов функции обратного вызова после загрузки всех изображений
    }
  };

  images.forEach((img) => {
    if (img.complete) {
      imageLoaded();
    } else {
      img.addEventListener('load', imageLoaded);
      img.addEventListener('error', imageLoaded); // В случае ошибки загрузки
    }
  });
};
export default checkMediaLoaded


