import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

export default function WorksConteiner({
  img,
  imgHover,
  seconImg,
  seconImgHover,
  title,
  description,
  secondTitle,
  secondDescription,
  blockId,
  side,
  imgReversePosution,
}) {
  let worksRef = useRef();

  useEffect(() => {
    let splitRow = new SplitType(
      `.TextBlokBoxFisrt${blockId}, .TextBlokBoxSecond${blockId}`,
      {
        types: ["words"],
      }
    );
    let animation = gsap
      .timeline({ paused: true })
      .fromTo(
        `.leftBox${blockId}`,
        { y: 100 },
        {
          y: 0,
          duration: 1,
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<"
      )
      .fromTo(
        `.worksImgFirst${blockId}`,
        { clipPath: "inset(100% 0% 0% 0%)" },
        {
          duration: 0.8,
          clipPath: "inset(0% 0% 0% 0%)",
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<"
      )
      .from(
        splitRow.words,
        {
          opacity: 0,
          ease: "ease.in",
          rotationZ: "0",
          y: 20,
          stagger: 0.04,
          duration: 1,
        },
        "<"
      )
      .fromTo(
        `.rightBox${blockId}`,
        { y: 150 },
        {
          y: 0,
          duration: 0.5,
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<0.2"
      )
      .fromTo(
        `.worksImgSecond${blockId}`,
        { clipPath: "inset(100% 0% 0% 0%)" },
        {
          clipPath: "inset(0% 0% 0% 0%)",
          duration: 0.5,
          ease: CustomEase.create("custom", "M0,0 C0.592,0 0.129,1 1,1 "),
        },
        "<0.2"
      );

    const elements = document.querySelectorAll(".worksBoxes");
    elements.forEach((element) => {
      element.addEventListener("mouseenter", function (event) {
        const hoverImage = event.currentTarget.querySelector(".worksImgHover");
        const textHeader = event.currentTarget.querySelector(
          ".worksBoxesTextBlok"
        );

        gsap.to(hoverImage, { duration: 0.3, clipPath: "inset(0% 0% 0% 0%)" });
        gsap.to(textHeader, { color: "blue" });
      });

      element.addEventListener("mouseleave", function (event) {
        const hoverImage = event.currentTarget.querySelector(".worksImgHover");
        const textHeader = event.currentTarget.querySelector(
          ".worksBoxesTextBlok"
        );
        gsap.to(hoverImage, {
          duration: 0.3,
          clipPath: "inset(0% 0% 100% 0%)",
        });
        gsap.to(textHeader, { color: "black" });
      });
    });

    let st;
      st = ScrollTrigger.create({
        trigger: worksRef.current,
        start: "center top",
        onEnterBack: () => animation.play(),
      });
    return () => {
      if (st) st.kill();
    };
  }, [blockId]);

  return (
    <>
      <div
        ref={worksRef}
        className={`worksMainContainers worksMainContainers${side}`}
      >
        <div
          className={`worksContainers ${
            imgReversePosution ? "worksContainersReverse" : ""
          }`}
        >
          <div className={`worksBoxes leftBox${blockId}`}>
            <div className={`worksBoxesTextBlok TextBlokBoxFisrt${blockId}`}>
              <p className="DescriptionNormalTextStyle worksTextDescription">
                {title}
              </p>
              <p className="DescriptionWorks DescriptionLittleTextStyle">
                {description}
              </p>
            </div>

            <div
              className={`imgWorksBox ${
                imgReversePosution ? "imgWorksBoxesSmallReverse" : ""
              }`}
            >
              <img
                className={`worksImg worksImgFirst worksImgFirst${blockId}`}
                src={img}
                alt="worksImg"
              />
              {imgHover ? <img
                className="worksImg worksImgFirst worksImgHover"
                src={imgHover}
                alt="worksImg"
              /> : null}
            </div>
          </div>

          <div className={`worksBoxes rightBox${blockId}`}>
            <div className={`worksBoxesTextBlok TextBlokBoxSecond${blockId}`}>
              <p className="DescriptionNormalTextStyle worksTextDescription">
                {secondTitle}
              </p>
              <p className="DescriptionWorks DescriptionLittleTextStyle">
                {secondDescription}
              </p>
            </div>
            <div className="imgWorksBox">
              <img
                className={`worksImg worksImgSecond worksImgSecond${blockId}`}
                src={seconImg}
                alt="worksImg"
              />
              {seconImgHover ? <img
                className="worksImg worksImgHover worksSecondImgHover"
                src={seconImgHover}
                alt="worksImg"
              /> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
