import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export default function ScrollUpAnimateLogo({ id, activeAnimate, logoColor }) {
  const logoAndScrollUp = useRef(null);
  const homeLogo = useRef(null);

  const handleLogoClick = () => {
    const currentScrollY = window.scrollY;
    gsap.to(window, { duration: 0.5, scrollTo: currentScrollY - 50 });
  };

  useEffect(() => {
    if (activeAnimate) {
      gsap.fromTo(
        logoAndScrollUp.current,
        { opacity: 0, y: 25 },
        { opacity: 1, y: 0, duration: 1, delay:0.3 }
      );
      gsap
        .timeline({ repeat: -1, delay: 2, repeatDelay: 1.5 })
        .to(homeLogo.current, {
          y: -15,
          duration: 0.5,
        })
        .to(homeLogo.current, {
          y: 0,
          duration: 0.4,
        });
    }
  }, [activeAnimate]);

  return (
    <>
      <div
        ref={logoAndScrollUp}
        className={`${id}  logoAndScrollUp`}
        onClick={handleLogoClick}
        style={{ cursor: "pointer" }}
      >
        <img
          ref={homeLogo}
          className="homeLogo"
          src={
            logoColor === "white"
              ? "/img/MaximagroupWhite.svg"
              : "/img/Maximagroup.svg"
          }
          alt="Description"
        />
        <p className="DescriptionNormalTextStyle ScrollUp">Scroll up</p>
      </div>
    </>
  );
}
