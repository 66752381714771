import "./App.css";
import "./media.css";
import React, { createContext, useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage/HomePage";
import AboutPage from "./pages/about/AboutPage";
import CasePage from "./pages/case/CasePage";
import PortfolioPage from "./pages/portfolio/PortfolioPage";
import PreLoader from "./components/PreLoader";
export const PreloaderContext = createContext();

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

export default function App() {
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [preloaderAnimateFinished, setPreloaderAnimateFinished] =
    useState(false);

    
  useEffect(() => {
    if (sessionStorage.getItem("preloaderShown") ) {
      setIsFirstVisit(false);
    }
  }, []);

  return (
    <PreloaderContext.Provider
      value={{
        preloaderAnimateFinished,
        setPreloaderAnimateFinished,
        isFirstVisit,
        setIsFirstVisit,
      }}
    >
      {isFirstVisit && <PreLoader />}
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/case" element={<CasePage />} />
        </Routes>
      </Router>
    </PreloaderContext.Provider>
  );
}
