import React from "react";
import WorksConteiner from "../../../components/WorksConteiner";
export default function Team() {
  const TeamValue = {
    firstBlock: {
      img: "./img/team/team1.jpg",
      title: "Eleanor Pena",
      description: "Dog Trainer",
      seconImg: "./img/team/team2.jpg",
      secondTitle: "Cameron Williamson",
      secondDescription: "Cameron Williamson",
    },
    secondBlock: {
      img: "./img/team/team3.jpg",
      title: "Dianne Russell",
      description: " Medical Assistant",
      seconImg: "./img/team/team4.jpg",
      secondTitle: "Robertson",
      secondDescription: "Medical Assistant",
    },
    thirdBlock: {
      img: "./img/team/team5.jpg",
      title: "Bessie Cooper",
      description: "Marketing Coordinator",
      seconImg: "./img/team/team6.jpg",
      secondTitle: "Darrell Steward",
      secondDescription: "President of Sales",
    },
  };
  return (
    <>
      <WorksConteiner
        blockId={"teamThirdBlock"}
        side={"left"}
        img={TeamValue.thirdBlock.img}
        title={TeamValue.thirdBlock.title}
        description={TeamValue.thirdBlock.description}
        seconImg={TeamValue.thirdBlock.seconImg}
        secondTitle={TeamValue.thirdBlock.secondTitle}
        secondDescription={TeamValue.thirdBlock.secondDescription}
        imgReversePosution={true}
      />
      <WorksConteiner
        blockId={"teamSecondBlock"}
        side={"right"}
        img={TeamValue.secondBlock.img}
        title={TeamValue.secondBlock.title}
        description={TeamValue.secondBlock.description}
        seconImg={TeamValue.secondBlock.seconImg}
        secondTitle={TeamValue.secondBlock.secondTitle}
        secondDescription={TeamValue.secondBlock.secondDescription}
      />
      <WorksConteiner
        blockId={"teamFirstBlock"}
        side={"left"}
        img={TeamValue.firstBlock.img}
        title={TeamValue.firstBlock.title}
        description={TeamValue.firstBlock.description}
        seconImg={TeamValue.firstBlock.seconImg}
        secondTitle={TeamValue.firstBlock.secondTitle}
        secondDescription={TeamValue.firstBlock.secondDescription}
      />
    </>
  );
}
