import React, { useEffect, useRef, useState, useContext } from "react";
import { PreloaderContext } from "../../../App";
import gsap from "gsap";
import Header from "../../../components/Header";
import ScrollUpAnimateLogo from "../../../components/ScrollUpAnimateLogo";
import useTextAnimation from "../../../components/SplitTextAnimateFunc";

export default function ProjectInfoCase() {
  const aboutRef = useTextAnimation();
  const aboutRefSec = useTextAnimation();
  const ref = useRef(null);
  const [activateAnimate, setActivateAnimate] = useState(false);
  const { preloaderAnimateFinished } = useContext(PreloaderContext);

  const mainBlockImg = {
    background: {
      backgroundImage: "url(/img/case/backMainCase.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
  };
  useEffect(() => {
    if (preloaderAnimateFinished) {
      setActivateAnimate(true);
      gsap.fromTo(
        ref.current,
        { opacity: 0, y: 100 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }
  }, [preloaderAnimateFinished]);
  return (
    <div className="projectInfoCase">
      <div className="CaseContainer">
        <div ref={aboutRef} className="BlockHeader BlockHeaderCase">
          <h4>
            <span style={{ fontStyle: "italic" }}>PROJECT</span>
            <br /> INFO
          </h4>
        </div>
        <div ref={aboutRefSec} className="rightTextBlockCase ">
          <div className="rightTextBoxCase">
            <div className="box50">
              <p className="DescriptionBigTextStyle mobileNormalText">Client</p>
            </div>
            <div className="box50">
              <p className="DescriptionNormalTextStyle">
                The Government of Dubai
              </p>
            </div>
          </div>
          <div className="rightTextBoxCase">
            <div className="box50">
              <p className="DescriptionBigTextStyle mobileNormalText">
                Services
              </p>
            </div>
            <div className="DescriptionNormalTextStyle box50">
              <p>Brand strategy</p>
              <p>Logo and corporate Identity</p>
              <p>Naming</p>
              <p>Design systems</p>
            </div>
          </div>
          <div className="rightTextBoxCase">
            <div className="box50">
              <p className="DescriptionBigTextStyle mobileNormalText">
                Live website
              </p>
            </div>
            <div className="box50">
              <p className="DescriptionNormalTextStyle webInderLine">
                edgesummit.com
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mainBlockCase" style={mainBlockImg.background}>
        <ScrollUpAnimateLogo
          id={"caseScrollLogo"}
          activeAnimate={activateAnimate}
          logoColor={"white"}
        />
        <div ref={ref} className="caseMainTitle">
          <h2  className="MaximumOfHeaderStyle">
            THE EDGE OF THE GOVERNMENT
          </h2>
        </div>
        <Header
          id={"caseHeader"}
          activeAnimate={activateAnimate}
          logoColor="white"
        />
      </div>
    </div>
  );
}
