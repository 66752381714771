import React from "react";
import useTextAnimation from "../../../components/SplitTextAnimateFunc";

export default function FeedBack() {
  const aboutRef = useTextAnimation();

  return (
    <>
      <div ref={aboutRef} className="FeedBackTextBlock">
        <h3 className="NavigationMenuBigText NavigationMenuBigTextBlack">
          “THIS SMART PIECE OF SOFTWARE REALLY CAN BRING OUT THE BEST IN YOUR HEADPHONES AND YOUR EARS”
        </h3>
        <p className="BlockHeaderItalic BlockHeaderItalicTeam">
        – FORBES, SOUNDID APP REVIEW
        </p>
      </div>
    </>
  );
}
