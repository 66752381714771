import React from "react";
import { NavLink } from "react-router-dom";

export default function MobileBurgerMenu({ setMenuOpen, menuOpen }) {
  return (
    <div className="mobileBurgerMenuBlock">
      <div
        className="mobileBurgerCenterMenu"
        onClick={() => {
          document.body.style.overflow = "scroll";
          setMenuOpen(!menuOpen);
        }}
      >
        <NavLink to="/" activeclassname="active-link">
          <h3 className="mobileBurgerTextStyle textHover"> Home </h3>
        </NavLink>
        <NavLink to="/about" activeclassname="active-link">
          <h3 className="mobileBurgerTextStyle textHover"> About us </h3>
        </NavLink>
        <NavLink to="/portfolio" activeclassname="active-link">
          <h3 className="mobileBurgerTextStyle textHover"> Portfolio</h3>
        </NavLink>
        <h3>
          <a
            href="mailto:info@maximagroup.ae"
            className="mobileBurgerTextStyle textHover"
          >
            Get in touch
          </a>
        </h3>
      </div>
      <div className="mobileBottomMenu">
        <div className="mobileActiveBurgerLogo">
          <img
            className="logoHeader"
            src="/img/MaximagroupWhiteLogo.svg"
            alt="Description"
          />
        </div>
        <div
          className="mobileActiveBurgerIcon"
          onClick={() => {
            document.body.style.overflow = "scroll";
            setMenuOpen(!menuOpen);
          }}
        >
          <img
            className="burgerIcon"
            src="/img/mobileBurgerWhite.svg"
            alt="burgerIcon"
          />
        </div>
      </div>
    </div>
  );
}
