import React from "react";
import useTextAnimation from "../../../components/SplitTextAnimateFunc";

export default function ValuesBlock() {
  const aboutRef = useTextAnimation();

  return (
    <>
      <div className="valueContainer">
        <div ref={aboutRef}  className="valueTextBox">
          <div>
            <h3 className="NavigationMenuBigText">INTEGRITY</h3>
            <p className="DescriptionNormalTextStyle">
              We build trust through responsible actions and honest
              relationships.
            </p>
          </div>
          <div>
            <h3 className="NavigationMenuBigText">RESPECT</h3>
            <p className="DescriptionNormalTextStyle">
              We value everyone and treat people with dignity and
              professionalism.
            </p>
          </div>
          <div>
            <h3 className="NavigationMenuBigText">ACCOUNTABILITY</h3>
            <p className="DescriptionNormalTextStyle">
              Each of us is responsible for our words, our actions, and our
              results.
            </p>
          </div>
          <div>
            <h3 className="NavigationMenuBigText">COMPASSION</h3>
            <p className="DescriptionNormalTextStyle">
              We are kind and caring to everyone we encounter.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
