import React from "react";
import useTextAnimation from "../../../components/SplitTextAnimateFunc";

export default function MaxOutput() {
  const ref = useTextAnimation();
  const titleRef = useTextAnimation();
  return (
    <div className="MaxOutput">
      <div ref={titleRef}>
        <p className="BlockHeader">
          <span style={{ fontStyle: "italic" }}>MAXIMMUM</span> <br />
          OF OUTPUT
        </p>
      </div>
      <div className="OutputConteinerSize">
        <div ref={ref} className="OutputConteiner">
          <div className="OutputBox">
            <h1 className="MaximumOfHeaderStyle MaximumOfHeaderStyleOutout">2013</h1>
            <p className="DescriptionNormalTextStyle">
              Of downloads after launch on Apple Store and Google Play.
            </p>
          </div>
          <div className="OutputBox">
            <h1 className="MaximumOfHeaderStyle MaximumOfHeaderStyleOutout">2k↗</h1>
            <p className="DescriptionNormalTextStyle">
              Of downloads after launch on Apple Store and Google Play. The
              highest growth rate amongst municipality apps.
            </p>
          </div>
          <div className="OutputBox">
            <h1 className="MaximumOfHeaderStyle MaximumOfHeaderStyleOutout">2k+</h1>
            <p className="DescriptionNormalTextStyle">
              Of downloads after launch on Apple Store and Google Play. The
              highest growth rate amongst municipality apps.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
