import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function LoderOnImagesBlock({ leftIMG, rightIMG }) {
  const GalleryMountainRef = useRef();
  const leftGalleryBox = useRef();
  const rightGalleryImg = useRef();
  const galleryLoaderLeftItem = useRef();
  const galleryLoaderRightItem = useRef();

  useEffect(() => {
    let animationGalleryMountain = gsap
      .timeline({ paused: true })
      .fromTo(leftGalleryBox.current, { y: -200 }, { y: 0, duration: 1.5 })
      .fromTo(
        leftGalleryBox.current,
        { clipPath: "inset(0% 0% 100% 0%)" },
        { clipPath: "inset(0% 0% 0% 0%)", duration: 1 },
        "<"
      )
      .fromTo(
        rightGalleryImg.current,
        { y: -400 },
        { y: 0, duration: 1.5 },
        "<0.2"
      )
      .fromTo(
        rightGalleryImg.current,
        { clipPath: "inset(25% 0% 75% 0%)" },
        { clipPath: "inset(0% 0% 0% 0%)", duration: 1 },
        "<"
      )
      .fromTo(
        galleryLoaderLeftItem.current,
        { y: -50, clipPath: "inset(100% 0% 0% 0%)" },
        { y: 0, clipPath: "inset(0% 0% 0% 0%)", duration: 0.8 },
        "-=0.8"
      )
      .fromTo(
        galleryLoaderRightItem.current,
        { y: -50, clipPath: "inset(100% 0% 0% 0%)" },
        { y: 0, clipPath: "inset(0% 0% 0% 0%)", duration: 0.6 },
        "<0.2"
      );

    let scrollTriggerInstance;
      scrollTriggerInstance = ScrollTrigger.create({
        trigger: GalleryMountainRef.current,
        start: "bottom-=25% top",
        end: "top top",
        onEnterBack: () => {
          animationGalleryMountain.play();
        },
      });
    return () => {
      if (scrollTriggerInstance) {
        scrollTriggerInstance.kill();
      }
    };

  }, []);

  return (
    <>
    <div className="aboutPageMarginBlock"></div>
      <div ref={GalleryMountainRef} className="galleryBlock">
        <div ref={leftGalleryBox} className="GalleryBox">
          <img
            className="galleryImg mountainLeft"
            src={leftIMG}
            alt="mountain"
          />
        </div>
        <div className="GalleryBox">
          <div ref={rightGalleryImg}>
            <img
              className="galleryImg mountainRight"
              src={rightIMG}
              alt="mountain"
            />
          </div>
          <div className="galleryLoader">
            <div
              ref={galleryLoaderLeftItem}
              className="galleryLoaderItem galleryLoaderLeftItem"
            ></div>
            <div
              ref={galleryLoaderRightItem}
              className="galleryLoaderItem galleryLoaderRightItem"
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
