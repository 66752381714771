import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";

export default function AnimateTitleBlock({
  headerFirstWord,
  headerSecondWord,
  headerThirdWord,
}) {
  const titleRef = useRef(null);
  const lineBoxRef = useRef(null);
  const containerRef = useRef(null);
  const blackLineRef = useRef(null);

  useEffect(() => {
    let animationAbout = gsap
      .timeline({ paused: true })
      .fromTo(titleRef.current, { y: 150, opacity: 0 }, { y: 0, opacity: 1 })
      .fromTo(
        lineBoxRef.current,
        { y: 150, opacity: 0 },
        { y: 0, opacity: 1, delay: 0.3 },
        "<"
      )
      .fromTo(
        containerRef.current,
        { y: 150, opacity: 0 },
        { y: 0, opacity: 1, delay: 0.3 },
        "<"
      )
      .fromTo(
        blackLineRef.current,
        { flex: "0 1 auto" },
        {
          flex: "1 1 auto",
          duration: 0.5,
          ease: CustomEase.create("custom", "M0,0 C0.674,0 0.344,1 1,1 "),
        }
      );

    let st = ScrollTrigger.create({
      trigger: titleRef.current,
      start: "top-=100% top",
      end: "top-=90% top",
      onEnterBack: () => {
        animationAbout.play();
      },
    });

    return () => {
      animationAbout.kill();
      if (st) {
        st.kill();
      }
    };
  }, []);

  return (
    <>
      <div className="MaximumBlockHeader MaximumOfHeaderStyle">
        <p ref={titleRef} >{headerFirstWord}</p>
        <div className="MaximumBlockHeaderBottomBox">
          <div ref={lineBoxRef} className="MaximumBlockHeaderLineBox">
            <p className="wordOf MaximumOfHeaderStyleItalic">
              {headerSecondWord}
            </p>
            <div ref={blackLineRef} className="MaximumBlackLine"></div>
          </div>
          <div ref={containerRef} className="MaximumOFContainer">
            <p>{headerThirdWord.toUpperCase()}</p>
          </div>
        </div>
      </div>
    </>
  );
}
