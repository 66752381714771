import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import AnimateTitleBlock from "../../../components/AnimateTitleBlock";
gsap.registerPlugin(ScrollTrigger);

export default function Services() {
  let servicesRef = useRef();

  useEffect(() => {
    let splitServicesHeader = new SplitType(".serviceBoxesHeader", {
      types: ["words"],
    });
    let splitServicesDescription = new SplitType(".serviceBoxesDescription", {
      types: ["words"],
    });

    let animationServices = gsap
      .timeline({ paused: true })
      .from(
        splitServicesHeader.words,
        {
          opacity: 0,
          y: 20,
          stagger: 0.05,
        },
        "<"
      )
      .from(
        splitServicesDescription.words,
        {
          opacity: 0,
          y: 20,
          stagger: 0.03,
        },
        "<"
      )
      .fromTo(
        ".leftServiceBox",
        {
          opacity: 0,
          y: 20,
        },
        { ease: "ease.in", y: 0, opacity: 1 },
        "<"
      );
      let stServices;
      let timeoutIdServices = setTimeout(() => {
        stServices = ScrollTrigger.create({
          trigger: ".servicesBlock",
          start: "center top",
          end: "top top",
          onEnterBack: () => {
            animationServices.play();
          },
        });
      }, 100); 
      return () => {
        clearTimeout(timeoutIdServices);
        if (stServices) stServices.kill();
      };
  }, []);
  return (
    <>
      <div ref={servicesRef} className="servicesBlock">
        <div className="serviceMainContainer">
          <div className="leftServiceBox">
            <h4 className="BlockHeader serviceBoxesHeader">
              <span className="BlockHeaderItalic">OUR</span>
              <br /> SERVICES
            </h4>
          </div>
          <div className="rightServiceBox">
            <div className="serviceSmallBoxes">
              <div className="BlockHeader serviceBoxesHeader">
                <p>
                  PRODUCT DESIGN &<br /> DEVELOPMENT
                </p>
              </div>
              <div className="DescriptionNormalTextStyle serviceBoxesDescription">
                <p>Conceptualization</p>
                <p>UI/UX Design & Prototyping</p>
                <p>Intranet and Digital Platforms</p>
                <p>Mobile Apps Development</p>
                <p>Market Benchmark and Analysis</p>
              </div>
            </div>
            <div className="serviceSmallBoxes">
              <div className="BlockHeader serviceBoxesHeader">
                <p>BRANDING</p>
              </div>
              <div className="DescriptionNormalTextStyle serviceBoxesDescription">
                <p>Brand Strategy</p>
                <p>Logo and corporate identity</p>
                <p>Naming</p>
                <p>Brand Guidelines</p>
                <p>Marketing collateral</p>
              </div>
            </div>
            <div className="serviceSmallBoxes">
              <div className="BlockHeader serviceBoxesHeader">
                <p>STRATEGY</p>
              </div>
              <div className="DescriptionNormalTextStyle serviceBoxesDescription">
                <p> Research and insight</p>
                <p>Product strategy</p>
                <p>MLP scoping</p>
                <p>Launch & onboarding</p>
                <p>Feasibility study</p>
                <p>Roadmapping</p>
              </div>
            </div>
            <div className="serviceSmallBoxes">
              <div className="BlockHeader serviceBoxesHeader">
                <p>TECH SUPPORT</p>
              </div>
              <div className="DescriptionNormalTextStyle serviceBoxesDescription">
                <p>Platform management</p>
                <p>Web Maintenance</p>
                <p>Hosting</p>
                <p>Domain management</p>
              </div>
            </div>
          </div>
        </div>
        
        <AnimateTitleBlock
        headerFirstWord="MAXIMUM"
        headerSecondWord="OF"
        headerThirdWord="SERVICES"
      />
  
      </div>
      <div className="servicesGradientBlock"></div>
    </>
  );
}
