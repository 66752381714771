import React, { useEffect, useRef, useContext } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import checkMediaLoaded from "./СheckMediaLoaded";
import { PreloaderContext } from "../App";

export default function ShortPreloader() {
  const { setPreloaderAnimateFinished } = useContext(PreloaderContext);
  const ref = useRef(null);
  const logoLeftItem = useRef(null);
  const logoRightItem = useRef(null);
  const loadTimeLine = gsap.timeline();

  useEffect(() => {
    loadTimeLine.fromTo(
      [logoLeftItem.current, logoRightItem.current],
      {
        clipPath: "inset(100% 0% 0% 0%)",
        webkitClipPath: "inset(100% 0% 0% 0%)",
        opacity: 1,
      },
      {
        clipPath: "inset(0% 0% 0% 0%)",
        webkitClipPath: "inset(0% 0% 0% 0%)",
        duration: 2,
        repeat: -1,
      }
    );
    checkMediaLoaded(() => {
      gsap
        .timeline()
        .fromTo(
          ref.current,
          { y: 0 },
          {
            y: '-100%',
            duration:1.5,
            delay:1,
            onComplete: () => {
              setPreloaderAnimateFinished(true);
              loadTimeLine.kill();
            },
          }
        )
        // .fromTo(
        //   ref.current,
        //   { opacity: 1 },
        //   {
        //     opacity: 0,
        //     duration: 1,
        //     delay: 1,
        //     onComplete: () => {
        //       setPreloaderAnimateFinished(true);
        //       loadTimeLine.kill();
        //     },
        //   }
        // )
        .fromTo(
          ref.current,
          { display: "block" },
          {
            display: "none",
          }
        );
      window.scrollTo(0, document.body.scrollHeight);
      ScrollTrigger.refresh();
    });
  }, []);

  return (
    <>
      <div ref={ref} className="shortPreloader">
        <div className="centerLogoItems">
          <div className="centerLogoBoxes">
            <div
              ref={logoLeftItem}
              className="centerLogoItemsStyle centerLogoLeftItem"
            >
              <img
                className="imgBackGradientLeftItem"
                src="img/gradientLeft.jpg"
                alt="back"
              />
            </div>
          </div>
          <div className="centerLogoBoxes">
            <div
              ref={logoRightItem}
              className="centerLogoItemsStyle centerLogoRightItem"
            >
              <img
                className="imgBackGradientRightItem"
                src="img/gradientRight.jpg"
                alt="back"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
