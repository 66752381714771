import React,{useEffect,useState} from "react";
import ProjectInfoCase from "./caseBlocks/ProjectInfoCase";
import AboutBlockCase from "./caseBlocks/AboutBlockCase";
import HowWeStarted from "./caseBlocks/HowWeStarted";
import HowWeStartedSecond from "./caseBlocks/HowWeStartedSecond";
import FeedBack from "./caseBlocks/FeedBack";
import MaxOutput from "./caseBlocks/MaxOutput";
import GallaryCase from "./caseBlocks/GallaryCase";
import GetInTouch from "../../components/GetInTouch";
import Footer from "../../components/Footer";
import ShortPreloader from "../../components/ShortPreLoader";

export default function CasePage() {
  const GalleryImg = {
    leftImgSrc: "/img/case/caseFirstBlockLeft.svg",
    rightImgSrc: "/img/case/caseFirstBlockRight.svg",
    bottomIMG: "/img/case/caseBottomImg.svg",
  };
  const HowWeStartedImg = {
    leftImgSrc: "/img/case/caseFirstBlockLeft.svg",
    rightImgSrc: "/img/case/caseFirstBlockRight.svg",
  };
  const HowWeStartedSecondImg = {
    leftImgSrc: "/img/case/caseLeftIMG.svg",
    middleIMG: "/img/case/caseMiddleImg.svg",
    rightImgSrc: "/img/case/caseRightImg.svg",
  };

  const aboutBlockCase = {
    imgSrc: "/img/case/caseIMG.svg",
  };

  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("preloaderShown")) {
      setIsPageLoading(true);
    }
  }, []);

  return (
    <>
      {isPageLoading && <ShortPreloader />}
      <div className="blocksBackgroundColorBlack">
        <Footer />
        <GetInTouch blockId={"casePage"} />
        <GallaryCase
          leftIMG={GalleryImg.leftImgSrc}
          rightIMG={GalleryImg.rightImgSrc}
          bottomIMG={GalleryImg.bottomIMG}
        />
      </div>
      <MaxOutput />
      <div className="greenGradientBlock"></div>
      <FeedBack />
      <HowWeStartedSecond
        leftIMG={HowWeStartedSecondImg.leftImgSrc}
        middleIMG={HowWeStartedSecondImg.middleIMG}
        rightIMG={HowWeStartedSecondImg.rightImgSrc}
      />
      <HowWeStarted
        leftIMG={HowWeStartedImg.leftImgSrc}
        rightIMG={HowWeStartedImg.rightImgSrc}
      />
      <AboutBlockCase imgOrVideo={aboutBlockCase.imgSrc} />
      <ProjectInfoCase />
    </>
  );
}
