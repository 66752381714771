import React from "react";
import useTextAnimation from "../../../components/SplitTextAnimateFunc";

export default function HowWeStarted({ leftIMG, rightIMG }) {
  const aboutRef = useTextAnimation();
  const aboutRefSec = useTextAnimation();

  return (
    <div className="HowWeStarted">
      <div className="caseFirstImgBox">
        <div className="GalleryBox">
          <img className="galleryImg" src={leftIMG} alt="CaseIMG" />
        </div>
        <div className="GalleryBox">
          <img className="galleryImg" src={rightIMG} alt="CaseIMG" />
        </div>
      </div>
      <div  className="CaseContainer">
        <div ref={aboutRef} className="BlockHeader BlockHeaderCase">
          <h4>
            HOW WE
            <br /> STARTED
          </h4>
        </div>
        <div className="rightTextBlockCase HowWeStartedright">
          <div ref={aboutRefSec} className="rightTextBoxCase">
            <div className="box50 box100mobile">
              <p className="DescriptionNormalTextStyle">
                We’re all about delivering simple, elegant, and logical design
                solutions which stay true to the intended meaning. Ut eget
                pharetra enim, sed feugiat tortor. Sed vel ullamcorper orci, id
                aliquet risus. Maxima Group is the first and only web3-oriented
                digital agency in the Middle East delivering stunning immersive
                digital experiences. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Cras dignissim bibendum turpis, ac hendrerit
                massa ultrices a.
              </p>
            </div>
            <div className="box50 box100mobile">
              <p className="DescriptionNormalTextStyle">
                Maxima Group is the first and only web3-oriented digital agency
                in the Middle East delivering stunning immersive digital
                experiences. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Cras dignissim bibendum turpis, ac hendrerit massa
                ultrices a.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
