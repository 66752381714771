import React from "react";
import useTextAnimation from "../../../components/SplitTextAnimateFunc";

export default function SecondAboutTextBlock() {
  const aboutRef = useTextAnimation();

  return (
    <>
      <div className="aboutGradientBlock"></div>
      <div className="SecondAboutPageTextBlock">
        <div ref={aboutRef} className="SecondAboutPageTextContainer">
          <p className="DescriptionNormalTextStyle SecondAboutPageLeftText">
            We’re all about delivering simple, elegant, and logical design
            solutions which stay true to the intended meaning. Ut eget pharetra
            enim, sed feugiat tortor. Sed vel ullamcorper orci, id aliquet
            risus. Maxima Group is the first and only web3-oriented digital
            agency in the Middle East delivering stunning immersive digital
            experiences. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Cras dignissim bibendum turpis, ac hendrerit massa ultrices a.
          </p>
          <p className="DescriptionNormalTextStyle SecondAboutPageRightText">
            Maxima Group is the first and only web3-oriented digital agency in
            the Middle East delivering stunning immersive digital experiences.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
            dignissim bibendum turpis, ac hendrerit massa ultrices a.
          </p>
        </div>
      </div>
    </>
  );
}
