import React, {useState, useEffect } from "react";
import MainBlock from "../../components/MainBlock";
import Footer from "../../components/Footer";
import GetInTouch from "../../components/GetInTouch";
import WorksConteiner from "../../components/WorksConteiner";
import ShortPreloader from "../../components/ShortPreLoader";

export default function PortfolioPage() {

  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("preloaderShown")) {
      setIsPageLoading(true);
    }
  }, []);

  const PortfolioWorks = {
    firstBlock: {
      img: "./img/works/works1.jpg",
      imgHover: "./img/works/works1hover.jpg",
      title: "Digital Sharjah",
      description: "The mega platform for Sharjah Government",
      seconImg: "./img/works/works2.jpg",
      seconImgHover: "./img/works/works2hover.jpg",
      secondTitle: "Sharrai Classified Ads",
      secondDescription: "Website & mobile apps",
    },
    secondBlock: {
      img: "./img/works/works3.jpg",
      imgHover: "./img/works/works3hover.jpg",
      title: "SEWA Brand",
      description: "Uplifting the brand with delicate touches",
      seconImg: "./img/works/works4.png",
      seconImgHover: "./img/works/works4hover.jpg",
      secondTitle: "BEEAH Group",
      secondDescription: "Tandeef website and mobile apps",
    },
    thirdBlock: {
      img: "./img/works/works5.jpg",
      imgHover: "./img/works/works5hover.jpg",
      title: "Sabaa' Sanabel",
      description: "Building the Brand of Sharjah' biggest wheat farms",
      seconImg: "./img/works/works6.jpg",
      seconImgHover: "./img/works/works6hover.jpg",
      secondTitle: "Holy Quran Academy",
      secondDescription: "A state-of-art platform, app, and corproate brand",
    },
  };

  return (
    <>
      {isPageLoading && <ShortPreloader />}
      <div className="blocksBackgroundColorBlack">
        <Footer />
        <GetInTouch blockId={"porfolioPage"} />
      </div>
      <div className="blackGradientBlock"></div>
      <div className="PortfolioWorksContainer">
        <WorksConteiner
          blockId={"potrfolioThirdBlock"}
          side={"left"}
          img={PortfolioWorks.thirdBlock.img}
          imgHover={PortfolioWorks.thirdBlock.imgHover}
          title={PortfolioWorks.thirdBlock.title}
          description={PortfolioWorks.thirdBlock.description}
          seconImg={PortfolioWorks.thirdBlock.seconImg}
          seconImgHover={PortfolioWorks.thirdBlock.seconImgHover}
          secondTitle={PortfolioWorks.thirdBlock.secondTitle}
          secondDescription={PortfolioWorks.thirdBlock.secondDescription}
        />
        <WorksConteiner
          blockId={"potrfolioSecondBlock"}
          side={"right"}
          img={PortfolioWorks.secondBlock.img}
          imgHover={PortfolioWorks.secondBlock.imgHover}
          title={PortfolioWorks.secondBlock.title}
          description={PortfolioWorks.secondBlock.description}
          seconImg={PortfolioWorks.secondBlock.seconImg}
          seconImgHover={PortfolioWorks.secondBlock.seconImgHover}
          secondTitle={PortfolioWorks.secondBlock.secondTitle}
          secondDescription={PortfolioWorks.secondBlock.secondDescription}
        />
        <WorksConteiner
          blockId={"potrfolioFirstBlock"}
          side={"left"}
          img={PortfolioWorks.firstBlock.img}
          imgHover={PortfolioWorks.firstBlock.imgHover}
          title={PortfolioWorks.firstBlock.title}
          description={PortfolioWorks.firstBlock.description}
          seconImg={PortfolioWorks.firstBlock.seconImg}
          seconImgHover={PortfolioWorks.firstBlock.seconImgHover}
          secondTitle={PortfolioWorks.firstBlock.secondTitle}
          secondDescription={PortfolioWorks.firstBlock.secondDescription}
        />
      </div>
      <div className="marginBlockPortfolio"></div>
      <MainBlock
        headerFirstWord="MAXIMUM"
        headerSecondWord="OF"
        headerThirdWord="WORK"
      />
    </>
  );
}
