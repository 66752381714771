import React,{useEffect,useState} from "react";
import MainBlock from "../../components/MainBlock";
import AboutTextBlock from "./aboutBlocks/AboutTextBlock";
import LoderOnImagesBlock from "../../components/LoderOnImagesBlock";
import AnimateTitleBlock from "../../components/AnimateTitleBlock";
import SecondAboutTextBlock from "./aboutBlocks/SecondAboutTextBlock";
import Team from "./aboutBlocks/Team";
import ThirdAboutTextBlock from "./aboutBlocks/ThirdAboutTextBlock";
import ValuesBlock from "./aboutBlocks/ValuesBlock";
import Footer from "../../components/Footer";
import GetInTouch from "../../components/GetInTouch";
import ShortPreloader from "../../components/ShortPreLoader";

export default function AboutPage() {
  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("preloaderShown")) {
      setIsPageLoading(true);
    }
  }, []);
  
  const LoaderImagesBlock = {
    leftImgSrc: "/img/loaderLeftImg.svg",
    rightImgSrc: "/img/loaderRightImg.svg",
    secondBlockLeftImgSrc: "/img/loaderRightImg.svg",
    secondBlockRightImgSrc: "/img/loaderRightImg.svg",
  };

  return (
    <>
      {isPageLoading && <ShortPreloader />}
      <div className="blocksBackgroundColorBlack">
        <Footer />
        <GetInTouch blockId={"aboutPage"} />
        <LoderOnImagesBlock
          leftIMG={LoaderImagesBlock.leftImgSrc}
          rightIMG={LoaderImagesBlock.rightImgSrc}
        />
      </div>
      <div className="blackGradientBlock"></div>
      <ValuesBlock />
      <AnimateTitleBlock
        headerFirstWord="MAXIMUM"
        headerSecondWord="OF"
        headerThirdWord="VALUES"
      />
      <div className="teamGradientBlocReverse"></div>
      <ThirdAboutTextBlock />
      <div className="teamGradientBlock"></div>
      <Team />
      <AnimateTitleBlock headerSecondWord="MAXIMUM" headerThirdWord="TEAM" />
      <div className="blocksBackgroundColorViolet">
        <SecondAboutTextBlock />
        <AnimateTitleBlock
          headerFirstWord="TAKING"
          headerSecondWord="MAXIMA"
          headerThirdWord="TO MAX"
        />
        <LoderOnImagesBlock
          leftIMG={LoaderImagesBlock.leftImgSrc}
          rightIMG={LoaderImagesBlock.rightImgSrc}
        />
      </div>
      <div className="violetGradientBlock"></div>
      <AboutTextBlock />
      <MainBlock
        headerFirstWord="WE ARE"
        headerSecondWord="MAXIMA"
        headerThirdWord="GROUP"
      />
    </>
  );
}
