import React, { useEffect, useState } from "react";
import VideoBlock from "./homeBlocks/VideoBlock";
import About from "./homeBlocks/About";
import Works from "./homeBlocks/Works";
import Services from "./homeBlocks/Services";
import Success from "./homeBlocks/Success";
import LoderOnImagesBlock from "../../components/LoderOnImagesBlock";
import GetInTouch from "../../components/GetInTouch";
import Footer from "../../components/Footer";
import MainBlock from "../../components/MainBlock";
import ShortPreloader from "../../components/ShortPreLoader";

export default function HomePage() {
  const LoaderImagesBlock = {
    leftImgSrc: "/img/mountainLeft.svg",
    rightImgSrc: "/img/mountainRight.svg",
  };
  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("preloaderShown")) {
      setIsPageLoading(true);
    }
  }, []);

  return (
    <>
      {isPageLoading && <ShortPreloader />}
      <div className="container">
        <div className="blocksBackgroundColorBlack">
          <Footer />
          <GetInTouch />
          <LoderOnImagesBlock
            leftIMG={LoaderImagesBlock.leftImgSrc}
            rightIMG={LoaderImagesBlock.rightImgSrc}
          />
        </div>
        <Success />
        <Services />
        <Works />
        <About />
        <VideoBlock />
        <MainBlock
          headerFirstWord="MAXIMUM"
          headerSecondWord="OF"
          headerThirdWord="DESIGN"
        />
      </div>
    </>
  );
}
