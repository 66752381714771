import React, { useRef, useState, useContext } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { CustomEase } from "gsap/CustomEase";
import { PreloaderContext } from "../App";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Timer from "./Timer";

export default function PreLoader() {
  const preloaderRef = useRef();
  const preloaderReach = useRef();
  const preloaderTheMaximum = useRef();
  const preloaderline = useRef();
  const preLoader = useRef();
  const wordReach = useRef();
  const logoLeftItem = useRef(null);
  const logoRightItem = useRef(null);
  const centerLogoItems = useRef(null);

  const [activateTimer, setActivateTimer] = useState(false);
  const { setPreloaderAnimateFinished } = useContext(PreloaderContext);

  function getScaleValue() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (width > 2000) {
      return 6;
    } else if (width > 1200) {
      return 5;
    } else if (width > 768) {
      return 4;
    } else if (width > 400) {
      return 2.5;
    } else {
      return 2;
    }
  }
  useGSAP(
    () => {
      const scaleValue = getScaleValue();
      gsap
        .timeline()
        .from(".timer", {
          yPercent: 100,
          ease: "power4.out",
          duration: 0.5,
          delay: 1.25,
          onComplete: () => {
            setActivateTimer(true);
            document.body.style.overflow = "hidden";
          },
        })
        .to(".timer", {
          duration: 1.5,
          ease: CustomEase.create("custom", "M0,0 C0.506,0 0.408,1 1,1 "),
          top: 0,
        })
        .fromTo(
          [logoLeftItem.current, logoRightItem.current],
          {
            clipPath: "inset(100% 0% 0% 0%)",
            webkitClipPath: "inset(100% 0% 0% 0%)",
            opacity: 1,
          },
          {
            clipPath: "inset(0% 0% 0% 0%)",
            webkitClipPath: "inset(0% 0% 0% 0%)",
            duration: 1.6,
          },
          "<"
        )
        .to(".timer", {
          duration: 0.5,
          ease: CustomEase.create("custom", "M0,0 C0.752,0 1,0.608 1,1 "),
          top: "-500",
        })
        .fromTo(
          preloaderReach.current,
          { opacity: 0, y: 150 },
          { opacity: 1, ease: "power4.out", y: 0, duration: 0.7 }
        )
        .fromTo(
          preloaderTheMaximum.current,
          { opacity: 0, y: 150 },
          {
            opacity: 1,
            duration: 0.5,
            ease: CustomEase.create("custom", "M0,0 C0.506,0 0.408,1 1,1 "),
            y: 0,
          },
          "-=0.7"
        )
        .fromTo(
          preloaderline.current,
          { flex: "0 1 auto" },
          {
            flex: "1 1 auto",
            duration: 0.5,
            ease: CustomEase.create("custom", "M0,0 C0.674,0 0.344,1 1,1 "),
          }
        )
        .to(preloaderline.current, {
          ease: CustomEase.create("custom", "M0,0 C0.752,0 1,0.608 1,1 "),
          duration: 0.5,
          transformOrigin: "right",
          scaleX: 0,
        })
        .to(
          centerLogoItems.current,
          {
            scale: scaleValue,
            yPercent: -100,
            ease: CustomEase.create("custom", "M0,0 C0.506,0 0.408,1 1,1 "),
            duration: 1.2,
          },
          "<0.1"
        )
        .to(
          preloaderTheMaximum.current,
          {
            y: -300,
            duration: 0.5,
            ease: CustomEase.create("custom", "M0,0 C0.67,0 1,0.46 1,1 "),
          },
          "<0.2"
        )
        .to(
          wordReach.current,
          {
            y: -300,
            duration: 0.5,
            ease: CustomEase.create("custom", "M0,0 C0.67,0 1,0.46 1,1 "),
          },
          "<0.1"
        )
        .to(
          ".imgBackGradientRightItem, .imgBackGradientLeftItem",
          {
            opacity: 1,
            duration: 1,
            onComplete: () => {
              window.scrollTo(0, document.body.scrollHeight);
              document.body.style.overflow = "scroll";
              ScrollTrigger.refresh();
            },
          },
          "-=1"
        )
        .to(
          centerLogoItems.current,
          {
            ease: CustomEase.create("custom", "M0,0 C0.506,0 0.408,1 1,1 "),
            y: -800,
            duration: 2,
          },
          "-=0.5"
        )
        .to(
          preLoader.current,
          {
            background: "#e7ebee",
            duration: "0.5",
          },
          "-=1"
        )
        .to(
          preLoader.current,
          {
            display: "none",
            onComplete: () => {
              setPreloaderAnimateFinished(true);
              sessionStorage.setItem("preloaderShown", "true");
              // ScrollTrigger.refresh();
            },
          },
          "<"
        );
    },
    { scope: preloaderRef }
  );

  return (
    <div ref={preloaderRef}>
      <div ref={preLoader} className="preLoader">
        <div className="preloaderHeader">
          <div ref={preloaderReach} className="MaximumOfDesignOfLinePreloader">
            <h2 ref={wordReach} className="wordReach">
              REACH
            </h2>
            <div ref={preloaderline} className="preloaderline"></div>
          </div>
          <div ref={preloaderTheMaximum} className="preloaderTheMaximum">
            <h2 className="theMax">THE MAXIMUM</h2>
          </div>
        </div>
        <div ref={centerLogoItems} className="centerLogoItems">
          <div
            ref={logoLeftItem}
            className="centerLogoItemsStyle centerLogoLeftItem"
          >
            <img
              className="imgBackGradientLeftItem"
              src="img/gradientLeft.jpg"
              alt="back"
            />
          </div>
          <div
            ref={logoRightItem}
            className="centerLogoItemsStyle centerLogoRightItem"
          >
            <img
              className="imgBackGradientRightItem"
              src="img/gradientRight.jpg"
              alt="back"
            />
          </div>
        </div>
        <Timer activateTimer={activateTimer} />
      </div>
    </div>
  );
}
