import React from "react";
import useTextAnimation from "../../../components/SplitTextAnimateFunc";

export default function ThirdAboutTextBlock() {
  const aboutRef = useTextAnimation();

  return (
    <>
      <div ref={aboutRef} className="ThirdAboutTextBlock">
        <h3 className="NavigationMenuBigText NavigationMenuBigTextBlack">
          WE PRIDE OURSELVES ON OUR ABILITY TO UNDERSTAND OUR CLIENTS' UNIQUE
          NEEDS AND GOALS, AND TO DELIVER SOLUTIONS THAT ARE BOTH VISUALLY
          STUNNING AND STRATEGICALLY SOUND.
        </h3>
        <p className="BlockHeaderItalic BlockHeaderItalicTeam">
          – YAHIA DAMANI, CEO OF MAXIMA GROUP."
        </p>
      </div>
    </>
  );
}
