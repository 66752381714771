import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";

export default function GetInTouch() {
  const getInTouchRef = useRef();
  const getInTouchWordGet = useRef(null);
  const getInTouchWordIn = useRef(null);
  const getTouchReachAndLine = useRef(null);
  const getInTouchWordTheMaximum = useRef(null);
  const getInTouchWordTouch = useRef(null);
  const getInTouchLine = useRef(null);


  useEffect(() => {
    let animationGetInTouch = gsap
      .timeline({ paused: true })
      .fromTo(
        getInTouchWordGet.current,
        { y: "-50vh", opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 1,
        }
      )
      .fromTo(
        getInTouchWordIn.current,
        { x: "20vw", y: -100, opacity: 0 },
        {
          x: 0,
          y: 0,
          opacity: 1,
          duration: 0.8,
        },
        "<"
      )
      .fromTo(
        getTouchReachAndLine.current,
        { y: -150, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 1,
        },
        "<0.1"
      )
      .fromTo(
        getInTouchWordTheMaximum.current,
        { y: -80, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.8,
        },
        "<0.2"
      )
      .fromTo(
        getInTouchWordTouch.current,
        { y: "20vh", opacity: 0 },
        {
          y: 0,
          opacity: 1,
        },
        "-=0.4"
      )
      .fromTo(
        getInTouchLine.current,
        { flex: "0 1 auto" },
        {
          flex: "1 1 auto",
          duration: 0.5,
          ease: CustomEase.create("custom", "M0,0 C0.674,0 0.344,1 1,1 "),
        }
      );

    let scrollTriggerInstance = ScrollTrigger.create({
        trigger: getInTouchRef.current,
        start: "top+=20% top",
        end: "top top",
        onEnterBack: () => {
          animationGetInTouch.play();
        },
      });

    return () => {
      if (scrollTriggerInstance) {
        scrollTriggerInstance.kill();
      }
    };

  }, []);
  return (
    <>
      <div ref={getInTouchRef} className="getInTouchBlock">
        <div className="GetInCOntainer">
          <div className="ReachTheMaximumGetInTouch BlockHeader">
            <div ref={getTouchReachAndLine} className="getTouchReachAndLine">
              <p className="wordReachGetInTouch">REACH</p>
              <div ref={getInTouchLine} className="GetInTouchLine"></div>
            </div>
            <p ref={getInTouchWordTheMaximum} className="getInTouchWordTheMaximum">THE MAXIMUM</p>
          </div>
          <div className="MaximumOfHeaderStyle GetInTouchBox">
            <p ref={getInTouchWordGet} className="getInTouchWordGet">GET</p>
            <p ref={getInTouchWordIn} className="getInTouchWordIn MaximumOfHeaderStyleItalic">IN</p>
            <p ref={getInTouchWordTouch} className="getInTouchWordTouch">TOUCH↳</p>
          </div>
        </div>
      </div>
    </>
  );
}
