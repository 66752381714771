import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { NavLink, Link } from "react-router-dom";
import MobileBurgerMenu from "./MobileBurgerMenu";

export default function Header({ activeAnimate, logoColor }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    if (activeAnimate) {
      gsap.fromTo(
        headerRef.current,
        { y: 200, opacity: 0 },
        { y: 0, opacity: 1, delay: 0.5 }
      );
    }
  }, [activeAnimate]);

  return (
    <>
      <div ref={headerRef} id="home" className="header headerBackground" >
        <div className="homeLogobox">
          <Link to="/">
            <img
              className="logoHeader"
              src={
                logoColor === "white"
                  ? "/img/MaximagroupWhiteLogo.svg"
                  : "/img/MaximagroupBlackLogo.svg"
              }
              alt="Description"
            />
          </Link>
        </div>
        <div
          className="burgerIcon"
          onClick={() => {
            document.body.style.overflow = "hidden";
            setMenuOpen(!menuOpen);
          }}
        >
          <img
            src={
              logoColor === "white"
                ? "/img/mobileBurgerWhite.svg"
                : "/img/mobileBurger.svg"
            }
            alt="burgerIcon"
          />
        </div>
        <div className="rightBoxHeader DescriptionNormalTextStyle"  >
          <div className="headerMenu">
            <NavLink to="/about" activeclassname="active-link">
              About us
            </NavLink>
            <NavLink to="/portfolio" activeclassname="active-link">
              Portfolio
            </NavLink>
          </div>
          <div className="HeaderGetinTouch">
            <a
              href="mailto:info@maximagroup.ae"
            >
              Get in touch ↳
            </a>
          </div>
        </div>
      </div>
      {menuOpen && (
        <MobileBurgerMenu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          logoColor={logoColor}
        />
      )}
    </>
  );
}
