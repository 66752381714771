import React from "react";
import useTextAnimation from "../../../components/SplitTextAnimateFunc";

export default function AboutBlockCase({imgOrVideo}) {
  const aboutRef = useTextAnimation();
  return ( 
    <div className="AboutBlockCase">
      <img className="AboutBlockCaseImg" src={imgOrVideo} alt="CaseIMG" />
      <div className="TextBlockCase">
        <div ref={aboutRef} className="CaseContainer">
          <div className="BlockHeaderItalic BlockHeaderCase">
            <h4> ABOUT</h4>
          </div>
          <div className="DescriptionBigTextStyle CaseDescriptionBigTextStyle">
            <p>
              We’re all about delivering simple, elegant, and logical design
              solutions which stay true to the intended meaning. Ut eget
              pharetra enim, sed feugiat tortor. Sed vel ullamcorper orci, id
              aliquet risus. Maxima Group is the first and only web3-oriented
              digital agency in the Middle East delivering stunning immersive
              digital experiences. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Cras dignissim bibendum turpis, ac hendrerit
              massa ultrices a.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
